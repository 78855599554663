<template>
  <div>
    <v-card flat>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REQUEST FOR PRINT THE CONTRACT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-combobox
          class="mt-6"
          :append-icon="icons.mdiAccountSearchOutline"
          v-model="search"
          @keyup="searching($event.target.value)"
          @change="get_search_items_info"
          :items="search_items"
          item-value="id"
          item-text="name"
          placeholder="Search by (Account Name/Account #)"
          ref="memberCombobox"
          dense
          outlined
        ></v-combobox>
        <v-form ref="form">
          <v-row>
            <v-col md="4" cols="12">
              <v-text-field v-model="id_no" label="ID #" dense outlined readonly></v-text-field>
            </v-col>
            <v-col md="8" cols="12">
              <v-text-field v-model="name" label="Name" dense outlined readonly></v-text-field>
            </v-col>
          </v-row>
        </v-form>

        <v-col cols="12" md="2">
          <v-btn color="primary" class="me-3 mt-4" @click="request_for_print" v-if="!saving">
            Request
          </v-btn>
          <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                               v-else></v-progress-circular>
        </v-col>
      </v-card-text>
      <v-card-text>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-uppercase">ID</th>
              <th class="text-center text-uppercase">Date Requested</th>
              <th class="text-center text-uppercase">FSC#</th>
              <th class="text-center text-uppercase">Name</th>
              <th class="text-center text-uppercase">Status</th>
              <th class="text-center text-uppercase">Action</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in requested_for_print" :key="item.id">
              <td>{{ item.id }}</td>
              <td class="text-center">
                {{ item.date }}
              </td>
              <td class="text-center">
                {{ item.deceased.fsc_no }}
              </td>
              <td class="text-center">
                {{ item.deceased.last_name + ', ' + item.deceased.first_name }}
              </td>
              <td class="text-center">
                {{ item.is_approved === 0 ? 'Not yet approve' : 'Approved' }}
              </td>
              <td class="text-center">
                <div v-if="item.is_approved === 1">
                  <v-icon
                    v-if="!is_deleting"
                    class="mr-2"
                    color="success"
                    @click="
                        print_contract(
                          requested_for_print[
                            requested_for_print
                              .map(function (x) {
                                return x.id
                              })
                              .indexOf(item.id)
                          ],
                        )
                      "
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular color="info" indeterminate v-else></v-progress-circular>
                </div>
              </td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiInformationOutline,
    mdiAccountCashOutline,
    mdiCash,
    mdiCellphone,
    mdiClipboardClockOutline,
    mdiContentSaveMoveOutline,
    mdiCashRefund,
    mdiShieldAccount,
    mdiGoogleCirclesCommunities,
    mdiBallotOutline,
    mdiAccountCash,
    mdiPrinter,
  } from '@mdi/js'
  import {mapGetters, mapActions} from 'vuex'
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        avatarImg: require('@/assets/images/avatars/1.png'),
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiCash,
          mdiCellphone,
          mdiClipboardClockOutline,
          mdiContentSaveMoveOutline,
          mdiCashRefund,
          mdiShieldAccount,
          mdiGoogleCirclesCommunities,
          mdiBallotOutline,
          mdiAccountCash,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        id: 0,
        id_no: '',
        name: '',

        saving: false,
        alert: false,
        alert_message: '',

        search: '',
        search_member: '',
        search_items: [],

        requested_for_print: [],
        is_deleting: false,
      }
    },
    created() {
      this.initialize_data()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
      ...mapGetters('authentication', [
        'branch',
        'branch_id',
        'year',
        'month_start',
        'month_end',
        'month_of',
        'user_id',
        'branch_address',
        'branch_contact_no',
      ]),
    },
    methods: {
      ...mapActions('deceased_client', ['search_deceased_client']),
      ...mapActions('request_for_print_contract', [
        'save_request_for_print_contract',
        'get_requested',
        'print_requested',
      ]),
      ...mapActions('system_data', ['change_snackbar']),
      initialize_data() {
        this.get_requested({
          branch_id: this.branch_id,
        }).then(response => {
          this.requested_for_print = response.data
        })
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      searching(value) {
        this.search_member = value
      },
      searching_search_items(value) {
        this.search_deceased_client({
          branch_id: this.branch_id,
          search_word: value,
        }).then(response => {
          this.search_items = response.data
        })
      },
      reset() {
        this.id = 0
        this.id_no = ''
        this.name = ''
        this.search = ''
        this.search_member = ''
        this.search_items = []
        this.saving = false
        this.alert = false
        this.alert_message = ''
        this.initialize_data()
      },
      get_search_items_info() {
        this.amount = 0
        this.amount_due = []
        if (this.search_items.length > 0) {
          var index = this.search_items
            .map(function (x) {
              return x.id
            })
            .indexOf(this.search.id)
          if (index != -1) {
            this.id = this.search_items[index].id
            this.id_no = this.search_items[index].id_no
            this.name = this.search_items[index].name
          } else {
            this.reset()
          }
        } else {
          this.reset()
        }
      },
      request_for_print() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('branch_id', this.branch_id)
          data.append('deceased_client_id', this.id)
          this.save_request_for_print_contract(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.reset()
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      },
      print_contract(datus) {
        this.is_deleting = true
        const datas = new FormData()
        datas.append('id', datus.id)
        this.print_requested(datas)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.reset()
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
        var imgData = this.company_logo
        var data = datus.deceased
        var caller_name = ''
        if (data.callers_info != null) {
          caller_name =
            data.callers_info.last_name + ', ' + data.callers_info.first_name + ' ' + data.callers_info.middle_name
        }
        var driver_pick_up = '_________________________'
        var driver_pick_up_amount = '_________________________'
        var driver_pick_up_signature = '_________________________'
        var helper_pick_up = '_________________________'
        var helper_pick_up_amount = '_________________________'
        var helper_pick_up_signature = '_________________________'
        var embalmer = '_________________________'
        var embalmer_amount = '_________________________'
        var embalmer_signature = '_________________________'
        var undertaker = '_________________________'
        var undertaker_amount = '_________________________'
        var undertaker_signature = '_________________________'
        var driver_deliver = '_________________________'
        var driver_deliver_amount = '_________________________'
        var driver_deliver_signature = '_________________________'
        var helper_deliver = '_________________________'
        var helper_deliver_amount = '_________________________'
        var helper_deliver_signature = '_________________________'
        var tarp = '_________________________'
        var tarp_amount = '_________________________'
        var tarp_signature = '_________________________'
        var flowers = '_________________________'
        var flowers_amount = '_________________________'
        var flowers_signature = '_________________________'
        var driver_interment = '_________________________'
        var driver_interment_amount = '_________________________'
        var driver_interment_signature = '_________________________'
        var helper_interment = '_________________________'
        var helper_interment_amount = '_________________________'
        var helper_interment_signature = '_________________________'
        var driver_tribute = '_________________________'
        var driver_tribute_amount = '_________________________'
        var driver_tribute_signature = '_________________________'

        // for (var x = 0; x < this.list_of_client[index].fund_data.length; x++) {
        //     var data = this.list_of_client[index].fund_data[x]
        //     if (data.particulars == 'driver_pick_up') {
        //         driver_pick_up = data.payee
        //         driver_pick_up_amount = data.amount
        //         driver_pick_up_signature = '_________________________'
        //     }
        //     if (data.particulars == 'helper_pick_up') {
        //         helper_pick_up = data.payee
        //         helper_pick_up_amount = data.amount
        //         helper_pick_up_signature = '_________________________'
        //     }
        //     if (data.particulars == 'embalmer') {
        //         embalmer = data.payee
        //         embalmer_amount = data.amount
        //         embalmer_signature = '_________________________'
        //     }
        //     if (data.particulars == 'undertaker') {
        //         undertaker = data.payee
        //         undertaker_amount = data.amount
        //         undertaker_signature = '_________________________'
        //     }
        //     if (data.particulars == 'driver_deliver') {
        //         driver_deliver = data.payee
        //         driver_deliver_amount = data.amount
        //         driver_deliver_signature = '_________________________'
        //     }
        //     if (data.particulars == 'helper_deliver') {
        //         helper_deliver = data.payee
        //         helper_deliver_amount = data.amount
        //         helper_deliver_signature = '_________________________'
        //     }
        //     if (data.particulars == 'tarp') {
        //         tarp = data.payee
        //         tarp_amount = data.amount
        //         tarp_signature = '_________________________'
        //     }
        //     if (data.particulars == 'flowers') {
        //         flowers = data.payee
        //         flowers_amount = data.amount
        //         flowers_signature = '_________________________'
        //     }
        //     if (data.particulars == 'driver_interment') {
        //         driver_interment = data.payee
        //         driver_interment_amount = data.amount
        //         driver_interment_signature = '_________________________'
        //     }
        //     if (data.particulars == 'helper_interment') {
        //         helper_interment = data.payee
        //         helper_interment_amount = data.amount
        //         helper_interment_signature = '_________________________'
        //     }
        //     if (data.particulars == 'driver_tribute') {
        //         driver_tribute = data.payee
        //         driver_tribute_amount = data.amount
        //         driver_tribute_signature = '_________________________'
        //     }
        // }

        var ttotal = (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? parseFloat(data.payable_on_damayan) : parseFloat(data.total) - parseFloat(data.payable_on_damayan)
        var ttotal2 = (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? parseFloat(data.ho_share) + parseFloat(data.total) : (parseFloat(data.ho_share) + parseFloat(data.total)) - parseFloat(data.payable_on_damayan)
        var damayan_data = [{
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        },]
        var damayan_data2 = [{
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        }, {
          text: ['-\n-'
          ],
        },];
        if (data.damayan_category != '') {
          if (data.damayan_category === 'WAIVED') {
            damayan_data = [
              {
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Discount: ',
                  {
                    text: this.formatPrice(parseFloat(data.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : this.formatPrice(parseFloat(data.casket_price) - parseFloat(data.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ];
            damayan_data2 = [
              {
                style: 'main_info',
                text: [
                  'HO Share: ',
                  {
                    text: this.formatPrice((parseFloat(data.ho_share))),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Discount: ',
                  {
                    text: this.formatPrice(parseFloat(data.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : this.formatPrice(parseFloat(data.casket_price) - parseFloat(data.payable_on_damayan)),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ];
          } else {
            ttotal = (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? parseFloat(data.payable_on_damayan) : parseFloat(data.total)
            ttotal2 = (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? parseFloat(data.ho_share) + parseFloat(data.total) : (parseFloat(data.ho_share) + parseFloat(data.total)) - parseFloat(data.payable_on_damayan)
            damayan_data = [
              {
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Payable: ',
                  {
                    text: this.formatPrice((parseFloat(data.payable_on_damayan))),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : this.formatPrice(parseFloat(data.casket_price) - (parseFloat(data.payable_on_damayan))),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ];
            damayan_data2 = [
              {
                style: 'main_info',
                text: [
                  'HO Share: ',
                  {
                    text: this.formatPrice((parseFloat(data.ho_share))),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Damayan Payable: ',
                  {
                    text: this.formatPrice((parseFloat(data.payable_on_damayan))),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Client Chapel Payables: ',
                  {
                    text: (data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : this.formatPrice(parseFloat(data.casket_price) + (parseFloat(data.payable_on_damayan))),
                    style: {
                      fontSize: 11,
                      color: 'green',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left',
                    },
                  },
                ],
              },
            ];
          }
        }
        var service_fee = [
          {
            columns: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Mode: ',
                      {
                        text: data.mode_of_service,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Type: ',
                  {
                    text: data.casket_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Discription: ',
                  {
                    text: data.description,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Casket Price: ',
                  {
                    text: this.formatPrice((data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : parseFloat(data.casket_price) + parseFloat(data.discount)),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Discount: ',
                  {
                    text: this.formatPrice(data.discount),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Net Price: ',
                  {
                    text: this.formatPrice((data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : parseFloat(data.casket_price)),
                    style: {
                      fontSize: 11,
                      color: 'orange',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: damayan_data,
          },
        ]
        var service_fee2 = [
          {
            columns: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Mode: ',
                      {
                        text: data.mode_of_service,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Type: ',
                  {
                    text: data.casket_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Discription: ',
                  {
                    text: data.description,
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Casket Price: ',
                  {
                    text: this.formatPrice((data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : parseFloat(data.casket_price) + parseFloat(data.discount)),
                    style: {
                      fontSize: 11,
                      color: 'red',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Discount: ',
                  {
                    text: this.formatPrice(data.discount),
                    style: {
                      fontSize: 11,
                      color: 'blue',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Casket Net Price: ',
                  {
                    text: this.formatPrice((data.mode_of_service === 'Damayan HG Package' || data.mode_of_service === 'Damayan FG Package') ? 0 : parseFloat(data.casket_price)),
                    style: {
                      fontSize: 11,
                      color: 'orange',
                      bold: true,
                      margin: [0, 2, 0, 2],
                      alignment: 'left'
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: damayan_data2,
          },
        ]
        var data_prices = [
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Kilometer Excess: ',
                  {
                    text: this.formatPrice(data.kilometer_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Tribute Fee: ',
                  {
                    text: this.formatPrice(data.tribute_fee),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Embalming Excess: ',
                  {
                    text: this.formatPrice(data.embalming_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Hospital Bill: ',
                  {
                    text: this.formatPrice(data.hospital_bill),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Kilometer: ',
                  {
                    text: data.kilometer + ' KM/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Viewing Excess: ',
                  {
                    text: this.formatPrice(data.viewing_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Accomodation Charge: ',
                  {
                    text: this.formatPrice(data.accomodation_charge),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Days of Embalming: ',
                  {
                    text: data.days_of_embalming + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Other Charges: ',
                  {
                    text: this.formatPrice(data.other_charges),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: ['Specify(Others): '],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Chapel Viewing: ',
                  {
                    text: data.chapel_view + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Special Price: ',
                  {
                    text: this.formatPrice(data.special_price),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  {
                    text: data.others_reason,
                    style: 'main_data_2',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Viewing at: ',
                  {
                    text: data.viewing_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Shipment Fee: ',
                  {
                    text: this.formatPrice(data.shipment_fee),
                    style: 'main_data',
                  },
                ],
              },
              {},
            ],
          },
          {
            columns: [
              {
                text: '',
              },
              {
                style: {fontSize: 15},
                text: [
                  'Php Total: ',
                  {
                    text: this.formatPrice(ttotal),
                    style: {fontsize: 20, color: 'red', bold: true},
                  },
                ],
              },
              {text: ''},
            ],
          },
        ]
        var data_prices2 = [
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Kilometer Excess: ',
                  {
                    text: this.formatPrice(data.kilometer_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Tribute Fee: ',
                  {
                    text: this.formatPrice(data.tribute_fee),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [''
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Embalming Excess: ',
                  {
                    text: this.formatPrice(data.embalming_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Hospital Bill: ',
                  {
                    text: this.formatPrice(data.hospital_bill),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Kilometer: ',
                  {
                    text: data.kilometer + ' KM/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Viewing Excess: ',
                  {
                    text: this.formatPrice(data.viewing_excess),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Accomodation Charge: ',
                  {
                    text: this.formatPrice(data.accomodation_charge),
                    style: 'main_data',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Days of Embalming: ',
                  {
                    text: data.days_of_embalming + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Other Charges: ',
                  {
                    text: this.formatPrice(data.other_charges),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: ['Specify(Others): '],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Chapel Viewing: ',
                  {
                    text: data.chapel_view + ' DAY/s',
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Special Price: ',
                  {
                    text: this.formatPrice(data.special_price),
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  {
                    text: data.others_reason,
                    style: 'main_data_2',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {
                style: 'main_info',
                text: [
                  'Viewing at: ',
                  {
                    text: data.viewing_type,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Shipment Fee: ',
                  {
                    text: this.formatPrice(data.shipment_fee),
                    style: 'main_data',
                  },
                ],
              },
              {},
            ],
          },
          {
            columns: [
              {
                text: '',
              },
              {
                style: {fontSize: 15},
                text: [
                  'Php Total: ',
                  {
                    text: this.formatPrice(ttotal2),
                    style: {fontsize: 20, color: 'red', bold: true},
                  },
                ],
              },
              {text: ''},
            ],
          },
        ]

        if (data.mode_of_service === 'Tie Up') {
          data_prices = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Mode: ',
                    {
                      text: data.mode_of_service,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Casket Price: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Shipment Fee: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Discription: ',
                    {
                      text: data.description,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Kilometer Excess: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Tribute Fee: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Casket Type: ',
                    {
                      text: data.casket_type,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Embalming Excess: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Hospital Bill: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Kilometer: ',
                    {
                      text: data.kilometer,
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Viewing Excess: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Accomodation Charge: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Days of Embalming: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Other Charges: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: ['Specify(Others): '],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Chapel Viewing: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    'Special Price: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {
                  style: 'main_info',
                  text: [
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  text: '',
                },
                {
                  style: {fontSize: 15},
                  text: [
                    'Php Total: ',
                    {
                      text: '___________',
                      style: 'main_data',
                    },
                  ],
                },
                {text: ''},
              ],
            },
          ]
        }
        //Always Copy this into info 2
        var info1 = [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE MEMORIAL CHAPEL CO.',
                  {text: this.branch_address, style: 'subheader'},
                  {
                    text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {text: 'Branch: ' + this.branch, style: 'main_info'},
                  {
                    text: 'FUNERAL SERVICE CONTRACT',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                  {
                    text: 'FSC No.: ' + data.fsc_no,
                    style: {
                      margin: [0, 2, 0, 2],
                      fontSize: 13,
                      alignment: 'right',
                      color: 'red',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Date of Service: ',
                      {
                        text: moment(data.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Date of Interment: ',
                      {
                        text: moment(data.date_of_internment, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name of Cadaver: ',
                      {
                        text: data.last_name + ', ' + data.first_name + ' ' + data.middle_name,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: ['Gender: ', {text: data.gender, style: 'main_data'}],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Birth: ',
                      {
                        text: moment(data.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Age: ',
                      {
                        text: data.age,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Civil Status: ',
                      {
                        text: data.civil_status,
                        style: 'main_data',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Death: ',
                      {
                        text: moment(data.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Religion: ',
                      {
                        text: data.religion,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'FAMILY REPRESENTATIVES',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name: ',
                      {
                        text: data.representatives_name,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Relationship: ',
                      {
                        text: data.representatives_relationship,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Address: ',
                      {
                        text: data.representatives_address,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Contact No.: ',
                      {
                        text: data.representatives_contact_no,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF SERVICE',
          {
            stack: service_fee,
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF CHARGES',
          {
            stack: data_prices,
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    table: {
                      body: [
                        [
                          {
                            stack: [
                              'FUNERAL SERVICE PACKAGES',
                              {
                                style: {alignment: 'justify', fontSize: 9},
                                ul: [
                                  'Casket',
                                  '9 days viewing',
                                  '3 days Chapel Viewing on NON AIR',
                                  'Free 25km',
                                  'Tarpaulin',
                                  'Tables and Chairs',
                                  'Funeral',
                                  'Funeral Hearse/Car',
                                  'Dispenser/Heater',
                                ],
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                  {
                    text: 'POLICY AND AGREEMENTS',
                    style: {alignment: 'justify', fontSize: 9},
                    ol: [
                      'Pick up service from residence/hospital to chapel.',
                      'Delivery from chapel to viewing residence.',
                      'Delivery from viewing place to internment site.',
                      'Family shall notice the Office (3) days in advance for the date and time of internment otherwise the burial will depend on the funeral coaches.',
                      'GOODLIFE CHAPELS will collect funeral equipments after burial service, damages to equipment will be charge accordingly.',
                      'Service payment must be full paid 2 days before internment.',
                      'The policy of GOODLIFE CHAPELS is "NO PAYMENT, NO INTERNMENT", the funeral car of the chapel will not bet used until the family has existing balance.',
                    ],
                  },
                ],
              },
              '',
              {
                columns: [
                  {
                    text: 'I hereby agree and understand the terms and conditions this contract with GOODLIFE MEMORIAL CHAPELS CO.',
                    style: {fontSize: 10},
                  },
                ],
              },
            ],
          },
        ]
        var info2 = [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE MEMORIAL CHAPEL CO.',
                  {text: this.branch_address, style: 'subheader'},
                  {
                    text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {text: 'Branch: ' + this.branch, style: 'main_info'},
                  {
                    text: 'FUNERAL SERVICE CONTRACT',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                  {
                    text: 'FSC No.: ' + data.fsc_no,
                    style: {
                      margin: [0, 2, 0, 2],
                      fontSize: 13,
                      alignment: 'right',
                      color: 'red',
                      bold: true,
                    },
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Date of Service: ',
                      {
                        text: moment(data.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Date of Interment: ',
                      {
                        text: moment(data.date_of_internment, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name of Cadaver: ',
                      {
                        text: data.last_name + ', ' + data.first_name + ' ' + data.middle_name,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: ['Gender: ', {text: data.gender, style: 'main_data'}],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Birth: ',
                      {
                        text: moment(data.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Age: ',
                      {
                        text: data.age,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Civil Status: ',
                      {
                        text: data.civil_status,
                        style: 'main_data',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Death: ',
                      {
                        text: moment(data.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {text: ''},
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Religion: ',
                      {
                        text: data.religion,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'FAMILY REPRESENTATIVES',
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Name: ',
                      {
                        text: data.representatives_name,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Relationship: ',
                      {
                        text: data.representatives_relationship,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Address: ',
                      {
                        text: data.representatives_address,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Contact No.: ',
                      {
                        text: data.representatives_contact_no,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF SERVICE',
          {
            stack: service_fee2,
          },
          '__________________________________________________________________________________________________',
          'SUMMARY OF CHARGES',
          {
            stack: data_prices2,
          },
          '__________________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    table: {
                      body: [
                        [
                          {
                            stack: [
                              'FUNERAL SERVICE PACKAGES',
                              {
                                style: {alignment: 'justify', fontSize: 9},
                                ul: [
                                  'Casket',
                                  '9 days viewing',
                                  '3 days Chapel Viewing on NON AIR',
                                  'Free 25km',
                                  'Tarpaulin',
                                  'Tables and Chairs',
                                  'Funeral',
                                  'Funeral Hearse/Car',
                                  'Dispenser/Heater',
                                ],
                              },
                            ],
                          },
                        ],
                      ],
                    },
                  },
                  {
                    text: 'POLICY AND AGREEMENTS',
                    style: {alignment: 'justify', fontSize: 9},
                    ol: [
                      'Pick up service from residence/hospital to chapel.',
                      'Delivery from chapel to viewing residence.',
                      'Delivery from viewing place to internment site.',
                      'Family shall notice the Office (3) days in advance for the date and time of internment otherwise the burial will depend on the funeral coaches.',
                      'GOODLIFE CHAPELS will collect funeral equipments after burial service, damages to equipment will be charge accordingly.',
                      'Service payment must be full paid 2 days before internment.',
                      'The policy of GOODLIFE CHAPELS is "NO PAYMENT, NO INTERNMENT", the funeral car of the chapel will not bet used until the family has existing balance.',
                    ],
                  },
                ],
              },
              '',
              {
                columns: [
                  {
                    text: 'I hereby agree and understand the terms and conditions this contract with GOODLIFE MEMORIAL CHAPELS CO.',
                    style: {fontSize: 10},
                  },
                ],
              },
            ],
          },
        ]
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            info1,
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: data.representatives_name,
                      style: {fontSize: 10},
                    },
                    {
                      text: data.chapel_manager,
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'FAMILY REPRESENTATIVES SIGNATURE',
                      style: {fontSize: 10},
                    },
                    {
                      text: 'CHAPEL MANAGER',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: 'AUDITOR',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      table: {
                        body: [
                          [
                            {
                              fillColor: '#0039f5',
                              stack: [
                                {
                                  text: 'CLIENT COPY',
                                  style: {fontSize: 16, color: 'white'},
                                },
                              ],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            info2,
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: data.representatives_name,
                      style: {fontSize: 10},
                    },
                    {
                      text: data.chapel_manager,
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'FAMILY REPRESENTATIVES SIGNATURE',
                      style: {fontSize: 10},
                    },
                    {
                      text: 'CHAPEL MANAGER',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: caller_name,
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: '_________________________________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'CALLERS SIGNATURE',
                      style: {fontSize: 10},
                    },
                    {
                      text: 'AUDITOR',
                      style: {fontSize: 10},
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: ' ',
                    },
                    {
                      table: {
                        body: [
                          [
                            {
                              fillColor: '#f7e307',
                              stack: [
                                {
                                  text: 'GOODLIFE COPY',
                                  style: {fontSize: 16, color: 'blacK'},
                                },
                              ],
                            },
                          ],
                        ],
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: ' ', style: 'main_info'},
                    {
                      text: 'WAIVER OF LIABILITIES',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {text: ' ', style: 'main_info'},
                  ],
                },
              ],
            },
            '\n\n\n',
            'Goodlife Memorial Chapel is offered to embalm of cadaver and a complete package of Funeral equipment used during the "FUNERAL WAKE"' +
            ' vigil of the Family and Funeral Car. LAPIDA, CANDLE, BARONG or any suit/dress of cadaver have not been offered and its contract by Memorial Chapel.',
            '\n\n\n',
            'I _________________________________________ residence of ________________________________________ representative of late FSC #: ' +
            data.fsc_no +
            ' rendered service by GOODLIFE MEMORIAL CHAPEL-' +
            this.branch +
            ' branch are not liable or any one of my family to hold the equipment or file any complain beyond' +
            ' Goodlife Memorial Service even the transaction between the staff and client.',
            '\n\n\n\n\n',
            {text: data.representatives_name},
            '____________________________________________\nNAME & SIGNATURE OF REPRESENTATIVE',
            '\n\n\n',
            {text: data.chapel_manager},
            '____________________________________________\nCHAPEL MANAGER',
            '\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n',

            //LIQUIDATION FORMS

            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'LIQUIDATION FORM',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {
                      text: 'FSC No.: ' + data.fsc_no,
                      style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Service: ',
                        {
                          text: moment(data.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Date of Interment: ',
                        {
                          text: moment(data.date_of_internment, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Name of Cadaver: ',
                        {
                          text: data.last_name + ', ' + data.first_name + ' ' + data.middle_name,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Address: ',
                        {
                          text: data.representatives_address,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: ['Gender: ', {text: data.gender, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Birth: ',
                        {
                          text: moment(data.date_of_birth, 'YYYY-MM-DD').format('MMMM DD, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Age: ',
                        {
                          text: data.age,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Civil Status: ',
                        {
                          text: data.civil_status,
                          style: 'main_data',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Death: ',
                        {
                          text: moment(data.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                  ],
                },
              ],
            },

            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'PARTICULARS', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'NAME', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'AMOUNT', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'SIGNATURE', style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Pick-up', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_pick_up, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_pick_up_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_pick_up_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Helper Pick-up', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_pick_up, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_pick_up_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_pick_up_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Embalmer', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: embalmer, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: embalmer_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: embalmer_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Undertaker', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: undertaker, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: undertaker_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: undertaker_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Deliver', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_deliver, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_deliver_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_deliver_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Helper Deliver', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_deliver, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_deliver_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_deliver_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Tarp', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: tarp, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: tarp_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: tarp_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Flowers', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: flowers, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: flowers_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: flowers_signature, style: 'main_data'}],
                    },
                  ],
                },
              ],
            },

            //Internment
            {
              columns: [{text: ' '}],
            },
            '_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _',
            {
              columns: [{text: ' '}],
            },

            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE MEMORIAL CHAPEL CO.',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'S.E.C No. PG201636035 TEL #: (082)333-1809 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {text: 'Branch: ' + this.branch, style: 'main_info'},
                    {
                      text: 'LIQUIDATION FORM',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                    {
                      text: 'FSC No.: ' + data.fsc_no,
                      style: {
                        margin: [0, 2, 0, 2],
                        fontSize: 13,
                        alignment: 'right',
                        color: 'red',
                        bold: true,
                      },
                    },
                  ],
                },
              ],
            },
            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date of Service: ',
                        {
                          text: moment(data.date_of_service, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Date of Interment: ',
                        {
                          text: moment(data.date_of_internment, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Name of Cadaver: ',
                        {
                          text: data.last_name + ', ' + data.first_name + ' ' + data.middle_name,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Address: ',
                        {
                          text: data.representatives_address,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: ['Gender: ', {text: data.gender, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Birth: ',
                        {
                          text: moment(data.date_of_birth, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Age: ',
                        {
                          text: data.age,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Civil Status: ',
                        {
                          text: data.civil_status,
                          style: 'main_data',
                        },
                      ],
                    },
                    {
                      style: 'main_info',
                      text: [
                        'Date of Death: ',
                        {
                          text: moment(data.date_of_death, 'YYYY-MM-DD').format('MMMM D, YYYY'),
                          style: 'main_data_2',
                        },
                      ],
                    },
                    {text: ''},
                  ],
                },
              ],
            },

            '__________________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'PARTICULARS', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'NAME', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'AMOUNT', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: 'SIGNATURE', style: 'main_data'}],
                    },
                  ],
                },

                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Interment', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_interment, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_interment_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_interment_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Helper Internment', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_interment, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_interment_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: helper_interment_signature, style: 'main_data'}],
                    },
                  ],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [{text: 'Driver Tribute', style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_tribute, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_tribute_amount, style: 'main_data'}],
                    },
                    {
                      style: 'main_info',
                      text: [{text: driver_tribute_signature, style: 'main_data'}],
                    },
                  ],
                },
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.is_deleting = false
      },
    },
  }
</script>
